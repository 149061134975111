<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos Vendidos</h4>
            <div class="small text-muted">Listado de productos que terminaron en ventas</div>
          </b-col>                        
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12">
          <b-card v-if="table.mostrarFiltros">
            <b-row>                          
              <b-col sm="3">
                <v-select :options="arr.select.invoice" v-model="filters.invoice" placeholder="Estado Facturación" :multiple="false" :select-on-tab="true"></v-select>                      
              </b-col>                    
              <b-col sm="2">
                <b-button variant="outline-dark" @click="loadByFilter()" size="sm">
                  <b-icon icon="filter" class="mr-1"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      
      <b-tabs class="mb-3">      
        <b-tab title="Listado">
          <b-card>
            <b-row>      
              <b-col lg="12">
                <b-table class="mb-0"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"  
                        v-if="table.items.length">                       

                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD MMMM YYYY')}}
                    </template>

                    <template v-slot:cell(seller)="data">
                      <div v-if="data.item.customer_seller">                        
                        <b-avatar :src="data.item.customer_seller.image"
                                  v-if="data.item.customer_seller.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>     
                        &nbsp;<b>{{data.item.customer_seller.name}}</b>   
                        <b-icon icon="circle-fill" v-if="!data.item.customer_seller.active" class="ml-2" variant="danger" v-b-tooltip.hover title="INACTIVO" />               
                      </div>
                    </template>

                    <template v-slot:cell(product)="data">
                      <div v-if="data.item.product">
                        <a href="javascript:void(0)" @click="goToMarketplaceInterested(data.item)">
                          <b>{{data.item.product.id}} - {{data.item.product.name}}</b>
                        </a>
                      </div>
                    </template>                
                    
                    <template v-slot:cell(commissions_total)="data">     
                      <div v-if="data.item.commissions_total>0">
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency', currency: "ARS"}).format(data.item.commissions_total)}}</b>
                      </div>
                    </template>              

                    <template v-slot:cell(payment)="data">                  
                      <div v-if="data.item.payment">                        
                        <b-icon icon="check2-circle" variant="success" scale="2" title="Cobrado"></b-icon>
                      </div>
                    </template>                   
                    
                    <template v-slot:cell(products_total)="data">     
                      <div v-if="data.item.products_total>0">
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency', currency: "ARS"}).format(data.item.products_total)}}</b>
                      </div>
                    </template>     

                    <template v-slot:cell(invoice)="data">
                      <div v-if="data.item.invoice">                        
                        <b-icon icon="check2-circle" variant="success" scale="2" title="Facturado"></b-icon>
                      </div>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="checkInvoice(data.item)" v-if="data.item.sold && !data.item.invoice">
                          <i class="fa fa-file crud-product-marketplace-action-icon ml-0 mr-0"></i> Facturado
                        </b-dropdown-item>   

                        <b-dropdown-item @click="uncheckInvoice(data.item)" v-if="data.item.invoice">
                          <i class="fa fa-file crud-product-marketplace-action-icon ml-0 mr-0"></i> No Facturado
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>        
        <b-tab title="Análisis">
          <div class="sales-flow-pivot">
              <pivot :data="pivot.data" 
                    :fields="pivot.fields" 
                    :available-field-keys="pivot.availableFieldKeys"
                    :row-field-keys="pivot.rowFieldKeys" 
                    :col-field-keys="pivot.colFieldKeys" 
                    :reducer="pivot.reducer"
                    :default-show-settings="false"
                    :is-data-loading="pivot.isDataLoading"
                    no-data-warning-text="No se encontraron datos para esta consulta"
                    available-fields-label-text="Campos Disponibles"
                    rows-label-text="Filas"
                    cols-label-text="Columnas"
                    hide-settings-text="Cerrar Configuración"
                    show-settings-text="Configurar Informe"
                    select-all-text="Seleccionar Todo"
                    unselect-all-text="Quitar selección">

                <template slot="value" slot-scope="{ value }">
                  {{ value.toLocaleString() }}     
                </template>
                
                <template v-slot:payment="{ value }">
                  <span v-if="value == '0'">No Cobrado</span>
                  <span v-if="value == '1'">Cobrado</span>
                </template>                           

                <template slot="loading">
                  <div class="text-center">
                    Cargando...
                  </div>
                </template>      

                <template slot="foot-customer" slot-scope="{ value }">
                  {{value}}
                </template>
              </pivot>   
          </div>
        </b-tab>          
      </b-tabs>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>
    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import moment from 'moment'
  import Pivot from '@marketconnect/vue-pivot-table'

  export default {     
    components: {
      Pivot,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'summaryContactSold',
          elements: {}
        },                   
        table : {
          items: [],
          mostrarFiltros: true,
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle', width:"80px"},
            {key: 'date', label: 'Fecha', class: 'align-middle', width:"100px"},
            {key: 'seller', label: 'Vendedor', class: 'align-middle', width:"200px"},                        
            {key: 'product', label: 'Producto', class: 'align-middle', width:"300px"},                        
            {key: 'commissions_total', label: 'A Cobrar', class: 'align-middle text-right', width:"100px"},            
            {key: 'payment', label: 'Cobrado', class: 'align-middle text-center', width:"80px"},
            {key: 'products_total', label: 'Precio Venta', class: 'align-middle text-right', width:"100px"},     
            {key: 'invoice', label: 'Facturado', class: 'align-middle text-center', width:"80px"},
            {key: 'f_action', label:'', class: 'align-middle', width:"100px"},
          ]
        },  
        pivot: {
          data: [],
          fields: [{
            key: 'sellers',
            getter: item => (item.customer_seller)?item.customer_seller.name : 'Sin Asignar',
            label: 'Vendedor',
            valueFilter: true,
          },{
            key: 'product',
            getter: item => (item.product)?item.product.name : 'Sin Asignar',
            label: 'Producto',
            valueFilter: true,
          },{
            key: 'payment',
            getter: item => item.payment,
            label: 'Cobrado',
            valueFilter: true,
            headerSlotName: 'payment',            
            valueFilterSlotName: 'payment'                    
          }, {
            key: 'date',
            getter: item => moment(item.date).format('DD/MM/YYYY'),
            label: 'Fecha',
            valueFilter: true
          }, {
            key: 'months',
            getter: item => moment(item.date).format('MMMM YYYY'),
            label: 'Mes',
            valueFilter: true
          }, {
            key: 'anio',
            getter: item => moment(item.date).format('YYYY'),
            label: 'Año',
            valueFilter: true
          }],   
          availableFieldKeys: ['date','anio', 'product'], 
          rowFieldKeys: ['sellers'],
          colFieldKeys: ['months','payment'],
          reducer: (sum, item) => sum + parseFloat(item.commissions_total),         
          isDataLoading: false, 
        },
        arr: {
          select:{
            invoice:[
              {code:true, label: 'Facturado'},
              {code:false, label: 'No Facturado'},              
            ],
          }
        }, 
        filters: {
          invoice: {},
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.loadByFilter()
    },
    methods: {
      loadByFilter() {
        var result = serviceAPI.filterSoldMarketplace(this.filters)

        result.then((response) => {
          var data = response.data
          this.table.items = data

          this.pivot.isDataLoading = true
          this.pivot.data = data         
          this.pivot.isDataLoading = false            
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      goToMarketplaceInterested(item) {
        this.$router.push({ name: 'ProductsStaffCrudContactInterested', params: {productID: item.product.id} })
      },

      // MARCO PRODUCTO COMO FACTURADO
      checkInvoice(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.checkInvoiceProductMarketplace({
          id: item.id          
        });
        
        result.then((response) => {          
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      // MARCO PRODUCTO COMO NO FACTURADO
      uncheckInvoice(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.uncheckInvoiceProductMarketplace({
          id: item.id
        });
        
        result.then((response) => {          
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })  
      },
    }    
  }
</script>

<style>
  .sales-flow-pivot table thead tr th:empty {
    background: #fff;
    color: #fff;
    text-align: center;
    border: none;
  }  
  .sales-flow-pivot table thead tr {
    background: #2f353a;
    color: #fff;
    text-align: center;
  }  
  .sales-flow-pivot .table th, .table td {
      padding: 1px;
  }
  .sales-flow-pivot table tbody tr th {
    background: #2f353a;
    color: #fff;
    text-align: right;    
    vertical-align: middle !important;    
    padding-right: 5px !important;
  }    
  .sales-flow-pivot table tbody tr td {    
    color: #2f353a;
    background: #e2e2e2;
    text-align: right;    
    font-weight: 600 !important;
    vertical-align: middle !important;    
    padding-right: 5px !important;
  }      
</style>